import React, { useState } from "react";
import { useEffect } from "react";
import style from "./style.module.css";
import LogoPanah from "../../Photo/panah.png";

const OwnerDatabase = (props) => {
  const url = props.url;
  const [result, setResults] = useState([]);
  const [jumlahKaryawanGym, setJumlahKaryawanGym] = useState("");
  const [jumlahKaryawanZumba, setJumlahKaryawanZumba] = useState("");
  const [jumlahTrainerGym, setJumlahTrainerGym] = useState("");
  const [jumlahTrainerZumba, setJumlahTrainerZumba] = useState("");
  const [jumlahMemberAktifGym, setJumlahMemberAktifGym] = useState("");
  const [jumlahMemberExpiredGym, setJumlahMemberExpiredGym] = useState("");
  const [jumlahMemberGym, setJumlahMemberGym] = useState("");
  const [jumlahFreelanceGym, setJumlahFreelanceGym] = useState("");
  const [jumlahFreelanceZumba, setJumlahFreelanceZumba] = useState("");
  const [divId, setDivId] = useState("attendanceVisitor");
  const [divDatabase, setDivDatabase] = useState("karyawanDatabase");
  const [detailVisitorGym, setDetailVisitorGym] = useState([]);
  const [detailVisitorZumba, setDetailVisitorZumba] = useState([]);
  const [databaseKaryawanGym, setDatabaseKaryawanGym] = useState([]);
  const [databaseKaryawanZumba, setDatabaseKaryawanZumba] = useState([]);
  const [databaseTrainerGym, setDatabaseTrainerGym] = useState([]);
  const [databaseTrainerZumba, setDatabaseTrainerZumba] = useState([]);
  const [databaseMemberAktifGym, setDatabaseMemberAktifGym] = useState([]);
  const [databaseMemberExpiredGym, setDatabaseMemberExpiredGym] = useState([]);
  const [dataAttendanceKaryawanGym, setDataAttendanceKaryawanGym] = useState(
    []
  );
  const [dataAttendanceKaryawanZumba, setDataAttendanceKaryawanZumba] =
    useState([]);
  const [dataAttendanceTrainerGym, setDataAttendanceTrainerGym] = useState([]);
  const [dataAttendanceTrainerZumba, setDataAttendanceTrainerZumba] = useState(
    []
  );
  const [panjangDatabaseKaryawanGym, setPanjangDatabaseKaryawanGym] =
    useState(0);
  const [panjangDatabaseKaryawanZumba, setPanjangDatabaseKaryawanZumba] =
    useState(0);
  const [panjangDatabaseTrainerGym, setPanjangDatabaseTrainerGym] = useState(0);
  const [panjangDatabaseTrainerZumba, setPanjangDatabaseTrainerZumba] =
    useState(0);
  const [panjangDatabaseMemberAktifGym, setPanjangDatabaseMemberAktifGym] =
    useState(0);
  const [panjangDatabaseMemberExpiredGym, setPanjangDatabaseMemberExpiredGym] =
    useState(0);
  let today = new Date();
  let year = today.getFullYear();
  let month = String(today.getMonth() + 1).padStart(2, "0"); // Bulan dimulai dari 0
  let day = String(today.getDate()).padStart(2, "0");

  let formattedDate = `${year}-${month}-${day}`;
  //
  async function GetDatabaseKartuGym(val) {
    await fetch(url + "/?op=getDatabaseKartuGym")
      .then((response) => response.json())
      .then((json) => {
        if (json !== null) {
          // karyawan
          if (json.filter((item) => item.status === "Karyawan"|| item.status === "Trainer").length > 0) {
            setJumlahKaryawanGym(
              json.filter((item) => item.status === "Karyawan").length
            );
            setJumlahTrainerGym(
              json.filter((item) => item.status === "Trainer").length
            );
            setPanjangDatabaseKaryawanGym(1);
            setDatabaseKaryawanGym(
              json.filter((item) => item.status === "Karyawan" || item.status === "Trainer" )
            );
          } else {
            setJumlahKaryawanGym(0);
            setJumlahTrainerGym(0);
            setPanjangDatabaseKaryawanGym(0);
            setDatabaseKaryawanGym([]);
          }
       
          //
          if (
            json.filter(
              (item) =>
                (item.status === "Member Umum"||item.status === "Member Pelajar") &&
                 item.expired_member >= formattedDate
            ).length > 0
          ) {
            setJumlahMemberAktifGym(
              json.filter(
                (item) =>
                (item.status === "Member Umum"||item.status === "Member Pelajar")&&
                  item.expired_member >= formattedDate
              ).length
            );
            setPanjangDatabaseMemberAktifGym(1);
            setDatabaseMemberAktifGym(
              json.filter(
                (item) =>
                (item.status === "Member Umum"||item.status === "Member Pelajar") &&
                  item.expired_member >= formattedDate
              )
            );
          } else {
            setJumlahMemberAktifGym(0);
            setPanjangDatabaseMemberAktifGym(0);
            setDatabaseMemberAktifGym([]);
          }
          if (
            json.filter(
              (item) =>
              (item.status === "Member Umum"||item.status === "Member Pelajar")&& item.expired_member < formattedDate
            ).length > 0
          ) {
            setJumlahMemberExpiredGym(
              json.filter(
                (item) =>
                (item.status === "Member Umum"||item.status === "Member Pelajar") &&
                  item.expired_member < formattedDate
              ).length
            );
            setPanjangDatabaseMemberExpiredGym(1);
            setDatabaseMemberExpiredGym(
              json.filter(
                (item) =>
                (item.status === "Member Umum"||item.status === "Member Pelajar")&&
                  item.expired_member < formattedDate
              )
            );
          } else {
            setJumlahMemberExpiredGym(0);
            setPanjangDatabaseMemberExpiredGym(0);
            setDatabaseMemberExpiredGym([]);
          }
        } else {
        }
      });
  }

  

  //
  async function GetDataPengunjungGym() {
    await fetch(url + "/?op=getDataPengunjungGym")
      .then((response) => response.json())
      .then((json) => {
        if (json !== null) {
          let jsonJumlahMemberGym = json.filter(
            (item) => (item.status === "Member Umum" || item.status === "Member Pelajar")
          ).length;
          let jsonJumlahFreelanceGym = json.filter(
            (item) => item.status === "Freelance"
          ).length;
          let jsonDataVisitorGym = json.filter(
            (item) => item.status === "Member Umum" || item.status === "Member Pelajar"|| item.status === "freelance"
          );

          setJumlahMemberGym(jsonJumlahMemberGym);
          setJumlahFreelanceGym(jsonJumlahFreelanceGym);
          setDetailVisitorGym(jsonDataVisitorGym);
        } else {
        }
      });
  }
  

  //
  function GetDataPengunjungGymWithDate(val) {
    fetch(url + "/?op=getDataPengunjungGymWithDate", {
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: "date=" + localStorage.getItem("dateVisitor"),
    })
      .then((response) => response.json())
      .then((json) => {
      
        if (json !== null) {
          if (json.filter((item) =>item.status === "Member Umum" || item.status === "Member Pelajar").length > 0) {
            setJumlahMemberGym(
              json.filter((item) => item.status === "Member Umum" || item.status === "Member Pelajar").length
            );
          } else {
            setJumlahMemberGym(0);
          }
          if (json.filter((item) => item.status === "Freelance").length > 0) {
            setJumlahFreelanceGym(
              json.filter((item) => item.status === "Freelance").length
            );
          } else {
            setJumlahFreelanceGym(0);
          }
          if (
            json.filter(
              (item) => item.status === "Member Umum" || item.status === "Member Pelajar"|| item.status === "Freelance"
            ).length > 0
          ) {
            setDetailVisitorGym(
              json.filter(
                (item) =>
                item.status === "Member Umum" || item.status === "Member Pelajar"|| item.status === "Freelance"
              )
            );
          } else {
            setDetailVisitorGym([]);
          }
        } else {
          setJumlahMemberGym(0);
          setJumlahFreelanceGym(0);
          setDetailVisitorGym([]);
        }
      });
  }
  
  // fungsi ubah tanggal
  const ChangeDate = (val) => {
    localStorage.setItem("dateVisitor", val);
    GetDataPengunjungGymWithDate(val);

  };
  // fungsi klik Div Id pilih attendance visitor atau yang lain
  const KlikDivId = (val) => {
    setDivId(val);
  };
  // fungsi klik Div Database pilih tampilan attendancenya apa
  const KlikDivDatabase = (val) => {
    setDivDatabase(val);
  };
  // fungsi jika attendance visitor

  const AttendanceVisitor = () => {
    return (
      <div className={style.containerAllAttendanceVisitor}>
        <div className={style.divAVKiri}>
          <div className={style.judulDivAVKiri}>Attendance Gym</div>
          <div className={style.isiDivAVKiri}>
            <table className={style.tableAVKiri}>
              <thead>
                <tr className={style.trStickyAVKiri}>
                  <th>Timestamp</th>

                  <th>Nama</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {detailVisitorGym.map((item, index) => (
                  <tr key={index}>
                    <td> {item.timestamp}</td>

                    <td> {item.nama}</td>
                    <td> {item.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
       
      </div>
    );
  };
  // fungsi menampilkan detail ID dari database yang dipilih
  const DetailDI = (val) => {
    switch (val) {
      case "karyawanDatabase":
        return (
          <div className={style.divDetailKaryawanDatabase}>
            <div className={style.tanggalDetailDatabaseKaryawan}>
              <input
                onChange={(e) =>
                  ChangeDateDatabaseKaryawan(e.target.value, "start")
                }
                type="date"
              />
              <div className={style.divPanah}>
                <img src={LogoPanah} />
              </div>
              <input
                onChange={(e) =>
                  ChangeDateDatabaseKaryawan(e.target.value, "finish")
                }
                type="date"
              />
            </div>
            <div className={style.divAtasDetailDatabase}>
              <div className={style.judulDivAtasDetailDatabase}>
                Attendance Karyawan {"&"} Trainer Gym
              </div>
              <div className={style.isiDivAtasDetailDatabase}>
                <table className={style.tableDetailDatabase}>
                  <thead>
                    <tr className={style.trSticky}>
                      <th>Nama</th>
                      <th>Jam Datang</th>
                      <th>Jam Pulang</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataAttendanceKaryawanGym.map((item, index) => (
                      <tr key={index}>
                        <td>{item.nama}</td>
                        <td>{item.jam_datang}</td>
                        <td>{item.jam_pulang}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className={style.divBawahDetailDatabase}>
              <div className={style.judulDivAtasDetailDatabase}>
                Total Jam Karyawan {"&"} Trainer
              </div>
              <div className={style.isiDivAtasDetailDatabase}>
                <table className={style.tableDetailDatabase}>
                  <thead>
                    <tr className={style.trSticky}>
                      <th>Nama</th>
                      <th>Total Jam</th>
                    </tr>
                  </thead>
                  <tbody>
                    {result.map((item, index) => (
                      <tr key={index}>
                        <td>{item.nama}</td>
                        <td>{item.total_jam}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        );
      

      case "trainerDatabase":
        return (
          <div className={style.divDetailKaryawanDatabase}>
            <div className={style.tanggalDetailDatabase}>
              <input
                onChange={(e) => ChangeDateDatabaseTrainer(e.target.value)}
                type="date"
              />
            </div>
            <div className={style.divAtasDetailDatabase}>
              <div className={style.judulDivAtasDetailDatabase}>
                Attendance Trainer Gym
              </div>
              <div className={style.isiDivAtasDetailDatabase}>
                <table className={style.tableDetailDatabase}>
                  <thead>
                    <tr className={style.trSticky}>
                      <th>Timestamp</th>
                      <th>Nama</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataAttendanceTrainerGym.map((item, index) => (
                      <tr key={index}>
                        <td>{item.timestamp}</td>
                        <td>{item.nama}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
           
          </div>
        );

      case "memberDatabase":
        break;

      default:
        break;
    }
  };
  // fungsi DAta id card
  const DataIdCard = () => {
    return (
      <div className={style.containerAllDataIdCard}>
        <div className={style.divDIKiri}>
          <div className={style["divDatabaseKaryawan"]}>
            <div
              onClick={() => KlikDivDatabase("karyawanDatabase")}
              style={{
                cursor: "pointer",
                color: divDatabase === "karyawanDatabase" ? "#2f69fd" : "",
                borderBottom:
                  divDatabase === "karyawanDatabase" ? "solid 1px #2f69fd" : "",
              }}
              className={style["judulDatabase"]}
            >
              Database Karyawan {"&"} Trainer 
            </div>
            <div className={style.isiDatabase}>
              <table className={style.tableDatabase}>
                <thead>
                  <tr className={style.trSticky}>
                    <th>Nama</th>
                    <th>Alamat</th>
                    <th>Nomor Hp</th>
                    <th>Status</th>
                    <th>Tgl Bergabung</th>
                  </tr>
                </thead>
                <tbody>
                  {panjangDatabaseKaryawanGym > 0
                    ? databaseKaryawanGym.map((item, index) => (
                        <tr key={index}>
                          <td>{item.nama}</td>
                          <td>{item.alamat}</td>
                          <td>{item.nomor_hp}</td>
                          <td>{item.status}</td>
                          <td>{item.awal_pendaftaran}</td>
                        </tr>
                      ))
                    : ""}
               
                </tbody>
              </table>
            </div>
          </div>
          
          <div className={style["divDatabaseMember"]}>
            <div className={style["judulDatabase"]}>Database Member</div>
            <div className={style.isiDatabase}>
              <table className={style.tableDatabase}>
                <thead>
                  <tr className={style.trSticky}>
                    <th>Nama</th>
                    <th>Alamat</th>
                    <th>Nomor Hp</th>
                    <th>Status</th>
                    <th>Tgl Bergabung</th>
                    <th>Start Member</th>
                    <th>Expired Member</th>
                  </tr>
                </thead>
                <tbody>
                  {panjangDatabaseMemberAktifGym > 0
                    ? databaseMemberAktifGym.map((item, index) => (
                        <tr key={index}>
                          <td>{item.nama}</td>
                          <td>{item.alamat}</td>
                          <td>{item.nomor_hp}</td>
                          <td>{item.status}</td>
                          <td>{item.awal_pendaftaran}</td>
                          <td>{item.start_member}</td>
                          <td>{item.expired_member}</td>
                        </tr>
                      ))
                    : ""}
                  {panjangDatabaseMemberExpiredGym > 0
                    ? databaseMemberExpiredGym.map((item, index) => (
                        <tr style={{ color: "red" }} key={index}>
                          <td>{item.nama}</td>
                          <td>{item.alamat}</td>
                          <td>{item.nomor_hp}</td>
                          <td>{item.awal_pendaftaran}</td>
                          <td>{item.start_member}</td>
                          <td>{item.expired_member}</td>
                        </tr>
                      ))
                    : ""}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className={style.divDIKanan}>{DetailDI(divDatabase)}</div>
      </div>
    );
  };

  // fungsi setelah memilih tanggal database karyawan
  const ChangeDateDatabaseTrainer = (val) => {
    localStorage.setItem("dateTrainer", val);
    GetDaftarHadirTrainerGym();

  };
  // fungsi setelah memilih tanggal database karyawan
  const ChangeDateDatabaseKaryawan = (val, id) => {
    switch (id) {
      case "start":
        localStorage.setItem("dateKaryawanStart", val);
        GetDaftarHadirKaryawanGym();
        //GetDaftarHadirKaryawanZumba();
        break;
      case "finish":
        localStorage.setItem("dateKaryawanFinish", val);
        GetDaftarHadirKaryawanGym();
        //GetDaftarHadirKaryawanZumba();
        break;
      default:
        break;
    }
  };

  //ambil daftar hadir trainer gym

  function GetDaftarHadirTrainerGym(val) {
    fetch(url + "/?op=getDaftarHadirTrainerGym", {
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: "date=" + localStorage.getItem("dateTrainer"),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json !== null) {
          setDataAttendanceTrainerGym(json);
        } else {
          setDataAttendanceTrainerGym([]);
        }
      });
  }


  //ambil daftar hadir karyawan gym

  function GetDaftarHadirKaryawanGym(val) {
    fetch(url + "/?op=getDaftarHadirKaryawanGym", {
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body:
        "dateStart=" +
        localStorage.getItem("dateKaryawanStart") +
        "&dateFinish=" +
        localStorage.getItem("dateKaryawanFinish"),
    })
      .then((response) => response.json())
      .then((json) => {
        
        if (json !== null) {
          setDataAttendanceKaryawanGym(json);
          const filteredData = json.filter((entry) => {
            const jamDatang = new Date(entry.jam_datang);
            return (
              jamDatang >=
                new Date(localStorage.getItem("dateKaryawanStart")) &&
              jamDatang <= new Date(localStorage.getItem("dateKaryawanFinish")+" 23:59:59")
            );
          });
          const totalHours = filteredData.reduce((acc, entry) => {
            const nama = entry.nama;
            const jamDatang = new Date(entry.jam_datang);
            const jamPulang = new Date(entry.jam_pulang);
            const hoursWorked = (jamPulang - jamDatang) / (1000 * 60 * 60); // convert ms to hours

            if (!acc[nama]) {
              acc[nama] = 0;
            }
            acc[nama] += hoursWorked;

            return acc;
          }, {});

          const resultArray = Object.keys(totalHours).map((nama) => ({
            nama,
            total_jam: totalHours[nama].toFixed(2),
          }));

          setResults(resultArray);
        } else {
          setDataAttendanceKaryawanGym([]);
        }
      });
  }
 
  //use effect
  useEffect(() => {
    localStorage.setItem("dateKaryawan", "");
    localStorage.setItem("dateKaryawanStart", "");
    localStorage.setItem("dateKaryawanFinish", "");
    localStorage.setItem("dateTrainer", "");
    localStorage.setItem("dateVisitor", "");
    GetDatabaseKartuGym();
   
    GetDataPengunjungGym();
  
    GetDaftarHadirKaryawanGym();
  
    GetDaftarHadirTrainerGym();
  
  }, []);

  useEffect(() => {
    // Set an interval to refocus the input field every 5 detik
    const interval = setInterval(() => {
      GetDataPengunjungGymWithDate();

      GetDatabaseKartuGym();

      GetDaftarHadirKaryawanGym();

      GetDaftarHadirTrainerGym();

    }, 5000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return (
    <div className={style.containerAll}>
      <div className={style.containerKiri}>
        <div
          onClick={() => KlikDivId("dataIdCard")}
          style={{
            cursor: "pointer",
            color: divId === "dataIdCard" ? "#2f69fd" : "",
            borderBottom: divId === "dataIdCard" ? "solid 1px #2f69fd" : "",
          }}
          className={style.judulMember}
        >
          Data Id Card
        </div>
        <div className={style.divTableMember}>
          <table className={style.tableMember}>
            <thead>
              <tr>
                <th>Subjek</th>
                <th>Gym</th>
          
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Karyawan</td>
                <td>{jumlahKaryawanGym}</td>
    
              </tr>
              <tr>
                <td>Trainer</td>
                <td>{jumlahTrainerGym}</td>

              </tr>
              <tr>
                <td>Member Aktif</td>
                <td>{jumlahMemberAktifGym}</td>
   
              </tr>
              <tr>
                <td>Member Expired</td>
                <td>{jumlahMemberExpiredGym}</td>
            
              </tr>
            </tbody>
          </table>
        </div>

        <div
          onClick={() => KlikDivId("attendanceVisitor")}
          style={{
            cursor: "pointer",
            color: divId === "attendanceVisitor" ? "#2f69fd" : "",
            borderBottom:
              divId === "attendanceVisitor" ? "solid 1px #2f69fd" : "",
          }}
          className={style.judulVisitor}
        >
          Visitor
          <div className={style.date}>
            <input type="date" onChange={(e) => ChangeDate(e.target.value)} />
          </div>
        </div>
        <div className={style.divTableMember}>
          <table className={style.tableMember}>
            <thead>
              <tr>
                <th>Subjek</th>
                <th>Gym</th>
          
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Member</td>
                <td>{jumlahMemberGym}</td>
        
              </tr>
              <tr>
                <td>Freelance</td>
                <td>{jumlahFreelanceGym}</td>

              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className={style.containerKanan}>
        {divId === "attendanceVisitor" ? AttendanceVisitor() : DataIdCard()}
      </div>
    </div>
  );
};
export default OwnerDatabase;
