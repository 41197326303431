import React from "react";
import { useState } from "react";
import style from "./style.module.css";

const TabelAkun = (props) => {
  const url = props.url;
  const [klik, setKlik] = useState("");
  const [boolPass, setBoolPass] = useState(false);
  const [value, setValue] = useState("");
  // function Update
  const Update = (id) => {
    if (id === "passwordDatabaseGym") {
      props.HasilDatabaseAkun(localStorage.getItem(id));
    } else {
    }
    setKlik("");
  };
  // function onchange
  const OnChange = (id, val) => {
    setBoolPass(true);
    setValue(val);
    localStorage.setItem(id, val);
  };

  // function Handle Klik
  const KlikTd = (val) => {
    setKlik(val);
  };
  return (
    <div className={style.tabelDatabase}>
      <table className={style.tabel}>
        <thead>
          <tr>
            <th>Login</th>
            <th>Password</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Gym</td>
            <td
              onClick={() => {
                setKlik("");
                setValue("");
              }}
              onDoubleClick={() => KlikTd(props.passwordGym)}
            >
              {klik === props.passwordGym ? (
                <input
                  value={boolPass ? value : props.passwordGym}
                  onChange={(e) =>
                    OnChange("passwordDatabaseGym", e.target.value)
                  }
                />
              ) : (
                props.passwordGym
              )}
            </td>
            <td className={style.tdAction}>
              <button onClick={() => Update("passwordDatabaseGym")}>
                Update
              </button>
            </td>
          </tr>
          <tr>
            <td>Owner</td>
            <td
              onClick={() => {
                setKlik("");
                setValue("");
              }}
              onDoubleClick={() => KlikTd(props.passwordOwner)}
            >
              {klik === props.passwordOwner ? (
                <input
                  value={boolPass ? value : props.passwordOwner}
                  onChange={(e) =>
                    OnChange("passwordDatabaseOwner", e.target.value)
                  }
                />
              ) : (
                props.passwordOwner
              )}
            </td>
            <td className={style.tdAction}>
              <button onClick={() => Update("passwordDatabaseOwner")}>
                Update
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default TabelAkun;
