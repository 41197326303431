import React, { forwardRef, useImperativeHandle } from "react";
import { useEffect } from "react";
import { useState } from "react";
import style from "./style.module.css";

const InputForm = forwardRef((props, ref) => {
 // const url = "https://192.168.169.136/kekes/gym.php";
  const url = "https://monitoring.kekesgym.site/gym.php";
  //const url ="https://09ab-182-2-71-244.ngrok-free.app/gym/gym.php";
  const [read, setRead] = useState(false);
  const [warna, setWarna] = useState(false);
  const [nilai, setNilai] = useState("");

  useImperativeHandle(ref, () => ({
    clearValue() {
      setNilai("");
    },
  }));

  //function AmbilNama
  const AmbilNama = (val) => {
    fetch(url + "/?op=getNamaAfiliator", {
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: "rfid=" + val,
    })
      .then((response) => response.json())
      .then((json) => {
        setWarna(false);
        setNilai(json[0]);
        localStorage.setItem("namaAfiliatorMemberGym", json[0]);
        localStorage.setItem("nomorHpAfiliatorMemberGym", json[1]);
      });
  };

  // function onchange
  const Change = (val) => {
    if (props.id === "afiliatorMemberGym") {
      localStorage.setItem(props.id, val);
      setNilai(val);
      setWarna(true);
      if (val.length === 10) {
        setRead(true);
        AmbilNama(val);
      }
    } else {
      localStorage.setItem(props.id, val.toUpperCase());
      setNilai(val.toUpperCase());
    }
  };

  //use Effect
  useEffect(() => {
    switch (props.id) {
      case "namaGymPerpanjangan":
        setNilai(localStorage.getItem("namaGymPerpanjangan"));
        setRead(true);
        break;
      case "alamatGymPerpanjangan":
        setNilai(localStorage.getItem("alamatGymPerpanjangan"));
        setRead(true);
        break;
      case "nomorGymPerpanjangan":
        setNilai(localStorage.getItem("nomorHpGymPerpanjangan"));
        setRead(true);
        break;
      case "statusGymPerpanjangan":
        setNilai(localStorage.getItem("statusGymPerpanjangan"));
        setRead(true);
        break;
      case "nextExpiredGymPerpanjangan":
       
        setRead(true);
        break;
        case "nextExpiredGym":
       
        setRead(true);
        break;
      default:
        break;
    }
  }, []);

  // use effect waktu gym perpanjangan
  useEffect(() => {
    if (props.id === "nextExpiredGymPerpanjangan") {
      if (props.nextExpired !== ""&&props.nextExpired!=="Pilih") {
        //
        let waktu = localStorage.getItem("waktuGymPerpanjangan");

        //

        // Mendapatkan tanggal hari ini
        let today = new Date();
        let year = today.getFullYear();
        let month = today.getMonth(); // Bulan dimulai dari 0
        let day = today.getDate();

        // Format tanggal hari ini
        let formattedDate = `${year}-${String(month + 1).padStart(
          2,
          "0"
        )}-${String(day).padStart(2, "0")}`;

        // Menentukan jumlah bulan yang ditambahkan berdasarkan waktu
        let tambahanBulan;
        if (waktu === "1 Bulan") {
          tambahanBulan = 1;
        } else if (waktu === "3 Bulan") {
          tambahanBulan = 3;
        } else if (waktu === "6 Bulan") {
          tambahanBulan = 6;
        } else if (waktu === "12 Bulan") {
          tambahanBulan = 12;
        }

        // Menghitung bulan dan tahun selanjutnya
        let totalBulan = month + tambahanBulan;
        let tahunSelanjutnya = year + Math.floor(totalBulan / 12);
        let bulanSelanjutnya = totalBulan % 12;

        // Membuat objek tanggal baru dengan bulan dan tahun yang telah disesuaikan
        let waktuSelanjutnya = new Date(
          tahunSelanjutnya,
          bulanSelanjutnya,
          day
        );

        // Memastikan tanggal valid (misalnya, 31 Januari + 1 bulan = 28 Februari/29 Februari)
        if (waktuSelanjutnya.getMonth() !== bulanSelanjutnya) {
          waktuSelanjutnya = new Date(
            tahunSelanjutnya,
            bulanSelanjutnya + 1,
            0
          ); // Set ke hari terakhir bulan sebelumnya
        }

        // Format tanggal hasil perhitungan
        let waktuExpiredYear = waktuSelanjutnya.getFullYear();
        let waktuExpiredMonth = String(
          waktuSelanjutnya.getMonth() + 1
        ).padStart(2, "0"); // Bulan dimulai dari 0
        let waktuExpiredDay = String(waktuSelanjutnya.getDate()).padStart(
          2,
          "0"
        );

        let formattedWaktuExpired = `${waktuExpiredYear}-${waktuExpiredMonth}-${waktuExpiredDay}`;
        setNilai(formattedWaktuExpired);
      } else {
        setNilai("");
      }
    }else if(props.id === "nextExpiredGym"){
      if (props.nextExpired !== "" && props.nextExpired!=="Pilih") {
        //
        let waktu = localStorage.getItem("waktuMemberGym");

        //

        // Mendapatkan tanggal hari ini
        let today = new Date();
        let year = today.getFullYear();
        let month = today.getMonth(); // Bulan dimulai dari 0
        let day = today.getDate();

        // Format tanggal hari ini
        let formattedDate = `${year}-${String(month + 1).padStart(
          2,
          "0"
        )}-${String(day).padStart(2, "0")}`;

        // Menentukan jumlah bulan yang ditambahkan berdasarkan waktu
        let tambahanBulan;
        if (waktu === "1 Bulan") {
          tambahanBulan = 1;
        } else if (waktu === "3 Bulan") {
          tambahanBulan = 3;
        } else if (waktu === "6 Bulan") {
          tambahanBulan = 6;
        } else if (waktu === "12 Bulan") {
          tambahanBulan = 12;
        }

        // Menghitung bulan dan tahun selanjutnya
        let totalBulan = month + tambahanBulan;
        let tahunSelanjutnya = year + Math.floor(totalBulan / 12);
        let bulanSelanjutnya = totalBulan % 12;

        // Membuat objek tanggal baru dengan bulan dan tahun yang telah disesuaikan
        let waktuSelanjutnya = new Date(
          tahunSelanjutnya,
          bulanSelanjutnya,
          day
        );

        // Memastikan tanggal valid (misalnya, 31 Januari + 1 bulan = 28 Februari/29 Februari)
        if (waktuSelanjutnya.getMonth() !== bulanSelanjutnya) {
          waktuSelanjutnya = new Date(
            tahunSelanjutnya,
            bulanSelanjutnya + 1,
            0
          ); // Set ke hari terakhir bulan sebelumnya
        }

        // Format tanggal hasil perhitungan
        let waktuExpiredYear = waktuSelanjutnya.getFullYear();
        let waktuExpiredMonth = String(
          waktuSelanjutnya.getMonth() + 1
        ).padStart(2, "0"); // Bulan dimulai dari 0
        let waktuExpiredDay = String(waktuSelanjutnya.getDate()).padStart(
          2,
          "0"
        );

        let formattedWaktuExpired = `${waktuExpiredYear}-${waktuExpiredMonth}-${waktuExpiredDay}`;
        setNilai(formattedWaktuExpired);
      } else {
        setNilai("");
      }
    }
  }, [props.nextExpired]);

  return (
    <div className={style.containerAll}>
      <div> {props.nama}</div>
      <input
        type={props.type === "number" ? "number" : ""}
        value={nilai}
        style={{ color: warna === true ? "transparent" : "black" }}
        readOnly={read === true ? true : false}
        onChange={(e) => Change(e.target.value)}
      />
    </div>
  );
});

export default InputForm;
