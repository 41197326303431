import React, { useState } from "react";
import { useEffect } from "react";
import style from "./style.module.css";
import LogoPanah from "../../Photo/panah.png";
import TabelAkun from "../../Component/TabelAkun";

const OwnerDatabase = (props) => {
  const url = props.url;
  const [warnaJudulDatabase, setWarnaJudulDatabase] = useState("");
  const [passwordGym, setPasswordGym] = useState("");
  const [passwordOwner, setPasswordOwner] = useState("");
  // function Klik Judul Database
  const KlikJudulDatabase = (val) => {
    setWarnaJudulDatabase(val);
    switch(val){
      case"Akun":
      fetch(url + "/?op=getDatabaseAkun")
      .then((response)=>response.json())
      .then((json)=>{
        if(json!==null){
          setPasswordGym(json[0].password);
          setPasswordOwner(json[1].password);
        }
      })
      break;
      default:
        break;
    }
  };
  // function hasil database akun
  const HasilDatabaseAkun=(val)=>{
    setPasswordGym(val);
  }
  // function isi database
  const IsiDatabase = (val) => {
    switch (val) {
      case "Akun":
        return(
       <TabelAkun HasilDatabaseAkun={HasilDatabaseAkun} passwordGym={passwordGym} passwordOwner={passwordOwner} url={url} />
        )
      default:
        break;
    }
  };
  // function tabel database
  const TabelDatabase = (val) => {
    return (
      <div className={style.isiContainerKanan}>
        <div className={style.judulTabelDatabase}>Tabel {val}</div>
        {IsiDatabase(val)}
      </div>
    );
  };
  //
  return (
    <div className={style.containerAll}>
      <div className={style.containerKiri}>
        <div className={style.judulContainerKiri}>Tabel Database</div>
        <div
          onClick={() => KlikJudulDatabase("Akun")}
          style={{ color: warnaJudulDatabase === "Akun" ? "#2f69fd" : "black" }}
          className={style.judulDatabase}
        >
          Akun
        </div>
        <div
          onClick={() => KlikJudulDatabase("Daftar Hadir Karyawan")}
          style={{
            color:
              warnaJudulDatabase === "Daftar Hadir Karyawan"
                ? "#2f69fd"
                : "black",
          }}
          className={style.judulDatabase}
        >
          Daftar Hadir Karyawan
        </div>
        <div
          onClick={() => KlikJudulDatabase("Daftar Hadir Pengunjung")}
          style={{
            color:
              warnaJudulDatabase === "Daftar Hadir Pengunjung"
                ? "#2f69fd"
                : "black",
          }}
          className={style.judulDatabase}
        >
          Daftar Hadir Pengunjung
        </div>
        <div
          onClick={() => KlikJudulDatabase("Database Kartu")}
          style={{
            color:
              warnaJudulDatabase === "Database Kartu" ? "#2f69fd" : "black",
          }}
          className={style.judulDatabase}
        >
          Database Kartu
        </div>
        <div
          onClick={() => KlikJudulDatabase("Harga Pengunjung")}
          style={{
            color:
              warnaJudulDatabase === "Harga Pengunjung" ? "#2f69fd" : "black",
          }}
          className={style.judulDatabase}
        >
          Harga Pengunjung
        </div>
        <div
          onClick={() => KlikJudulDatabase("Harga Personal Trainer")}
          style={{
            color:
              warnaJudulDatabase === "Harga Personal Trainer"
                ? "#2f69fd"
                : "black",
          }}
          className={style.judulDatabase}
        >
          Harga Personal Trainer
        </div>
        <div
          onClick={() => KlikJudulDatabase("History Pengeluaran Inventory Gym")}
          style={{
            color:
              warnaJudulDatabase === "History Pengeluaran Inventory Gym"
                ? "#2f69fd"
                : "black",
          }}
          className={style.judulDatabase}
        >
          History Pengeluaran Inventory Gym
        </div>
        <div
          onClick={() => KlikJudulDatabase("History Personal Trainer")}
          style={{
            color:
              warnaJudulDatabase === "History Personal Trainer"
                ? "#2f69fd"
                : "black",
          }}
          className={style.judulDatabase}
        >
          History Personal Trainer
        </div>
        <div
          onClick={() => KlikJudulDatabase("Inventory Gym")}
          style={{
            color: warnaJudulDatabase === "Inventory Gym" ? "#2f69fd" : "black",
          }}
          className={style.judulDatabase}
        >
          Inventory Gym
        </div>
        <div
          onClick={() => KlikJudulDatabase("Personal Trainer")}
          style={{
            color:
              warnaJudulDatabase === "Personal Trainer" ? "#2f69fd" : "black",
          }}
          className={style.judulDatabase}
        >
          Personal Trainer
        </div>
        <div
          onClick={() => KlikJudulDatabase("Trainer Reader")}
          style={{
            color:
              warnaJudulDatabase === "Trainer Reader" ? "#2f69fd" : "black",
          }}
          className={style.judulDatabase}
        >
          Trainer Reader
        </div>
      </div>
      <div className={style.containerKanan}>
        {warnaJudulDatabase === "" ? "" : TabelDatabase(warnaJudulDatabase)}
      </div>
    </div>
  );
};
export default OwnerDatabase;
