import React from "react";
import style from "./style.module.css";
import LogoPanah from "../../Photo/panah.png";
import LogoExcel from "../../Photo/logoExcel.png";
import { useState } from "react";
import { useEffect } from "react";
import * as XLSX from 'xlsx';

const OwnerPajak = (props) => {
    const url = props.url;
    const [listData, setListData] = useState([]);
    const [totalPendapatan, setTotalPendapatan] = useState(0);
    const [totalPajak, setTotalPajak] = useState(0);
    const [pajakPusat, setPajakPusat] = useState(0);
    const [pajakDaerah, setPajakDaerah] = useState(0);

    useEffect(() => {
        localStorage.setItem("datePajakStart", "");
        localStorage.setItem("datePajakFinish", "");
        AmbilDataIncome();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            AmbilDataIncome();
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    function AmbilDataIncome(val) {
        fetch(url + "/?op=getDataIncome", {
            method: "post",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body:
                "dateStart=" +
                localStorage.getItem("datePajakStart") +
                "&dateFinish=" +
                localStorage.getItem("datePajakFinish"),
        })
            .then((response) => response.json())
            .then((json) => {
                if (json !== null) {
                    setListData(json);
                    let total = 0;

                    total = json.reduce((accumulator, item) => accumulator + parseInt(item.total), 0);
                    setTotalPendapatan(total);
                    setTotalPajak(parseInt((total *0.14).toFixed(0)));
                    setPajakPusat(parseInt((total *0.14*0.005).toFixed(0)));
                    setPajakDaerah(parseInt((total *0.14*0.1).toFixed(0)));
                } else {
                    setListData([]);
                }
            });
    }

    const ChangeDate = (val, id) => {
        switch (id) {
            case "start":
                localStorage.setItem("datePajakStart", val);
                AmbilDataIncome();
                break;
            case "finish":
                localStorage.setItem("datePajakFinish", val);
                AmbilDataIncome();
                break;
            default:
                break;
        }
    };

    const handleExportExcel = () => {
        // Copy listData and append totalPendapatan and totalPajak rows
        const dataToExport = [...listData, {}, { timestamp: '', nama_barang: 'Total Pendapatan', out_barang: '', total: totalPendapatan },
         { timestamp: '', nama_barang: 'Total Pajak', out_barang: '', total: totalPajak },
         { timestamp: '', nama_barang: 'Pajak Pusat', out_barang: '', total: pajakPusat },
         { timestamp: '', nama_barang: 'Pajak Daerah', out_barang: '', total: pajakDaerah }
        ];

        const ws = XLSX.utils.json_to_sheet(dataToExport);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Data");
        XLSX.writeFile(wb, "data_pajak_detail.xlsx");
    };

    // export by date
    const handleExportExcelByDate = () => {
       // Kelompokkan data berdasarkan tanggal
    const groupedData = listData.reduce((acc, item) => {
        const date = new Date(item.timestamp).toLocaleDateString('id-ID'); // Ekstrak tanggal dari timestamp
        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(item);
        return acc;
    }, {});

    // Hitung total untuk setiap tanggal dan kalikan dengan 12%
    const dataToExport = [];
    for (const date in groupedData) {
        const totalIncome = groupedData[date].reduce((acc, item) => acc + parseInt(item.total), 0);
        const totalPajak = totalIncome * 0.14; // Menghitung pajak 12%
        dataToExport.push({
            tanggal: date,
            totalPendapatan: totalIncome,
            pajak14Persen: totalPajak,
            pajakPusat:0.005*totalPajak,
            pajakDaerah:0.1*totalPajak
        });
    }

    // Mengonversi data menjadi format yang dapat diatur
    const ws = XLSX.utils.aoa_to_sheet([
        ["Tanggal", "Total Pendapatan", "Pajak 14%","Pajak Pusat","Pajak Daerah"], // Header
        ...dataToExport.map(item => [item.tanggal, item.totalPendapatan, item.pajak14Persen,
             item.pajakPusat, item.pajakDaerah]) // Data
    ]);

    // Hitung total pendapatan pajak 12% dan pajak daerah
    const totalPendapatanUtuh = dataToExport.reduce((acc, item) => acc + parseInt(item.totalPendapatan), 0)
    const totalPendapatanPajak14Persen = dataToExport.reduce((acc, item) => acc + item.pajak14Persen, 0);
    const totalPajakPusat = totalPendapatanPajak14Persen * 0.005; // Pajak daerah 10% dari total pajak 12%
    const totalPajakDaerah = totalPendapatanPajak14Persen * 0.1; // Pajak daerah 10% dari total pajak 12%

    // Tambahkan baris tambahan dengan total
    XLSX.utils.sheet_add_aoa(ws, [
        [], // Baris kosong untuk pemisah
        ["Total Pendapatan Utuh", totalPendapatanUtuh],
        ["Total Pendapatan Pajak 14%", totalPendapatanPajak14Persen],
        ["Total Pajak Pusat (0.5% dari Total Pendapatan Pajak 14%)", totalPajakPusat],
        ["Total Pajak Daerah (10% dari Total Pendapatan Pajak 14%)", totalPajakDaerah]
    ], { origin: -1 }); // Menambahkan di akhir worksheet

    // Buat workbook dan worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Data");
    XLSX.writeFile(wb, "data_pajak_cumulative.xlsx");
    };


    return (
        <div className={style.containerAll}>
            <div className={style.divAtas}>
                <div className={style.divLogoExcels}>
                    <div className={style.excelPusat}>
                <img src={LogoExcel} onClick={handleExportExcel} />
                <div>Detail</div>
                </div>
                <div className={style.excelDaerah}>
                <img src={LogoExcel} onClick={handleExportExcelByDate} alt="Export Excel by Date" />
                <div>Cumulative</div>
                </div>
                </div>
                <input type="date" onChange={(e) => ChangeDate(e.target.value, "start")} />
                <div className={style.divLogoPanah}>
                    <img src={LogoPanah} alt="logo_panah" />
                </div>
                <input type="date" onChange={(e) => ChangeDate(e.target.value, "finish")} />
            </div>
            <div className={style.divIsi}>
                <div className={style.divData} id="divData">
                    <div className={style.divTable}>
                        <table className={style.table}>
                            <thead>
                                <tr className={style.trSticky}>
                                    <th>Timestamp</th>
                                    <th>Nama</th>
                                    <th>Jumlah</th>
                                    <th>Harga</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listData.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.timestamp}</td>
                                        <td>{item.nama_barang}</td>
                                        <td>{item.out_barang}</td>
                                        <td>{item.total}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className={style.divTotalPendapatan}>
                        <div>Total Pendapatan</div>
                        <div>{totalPendapatan}</div>
                    </div>
                    <div className={style.divTotalPajak}>
                        <div>Total Pajak (14% Pendapatan)</div>
                        <div>{totalPajak}</div>
                    </div>
                    <div className={style.divTotalPajak}>
                        <div>Pajak Pusat </div>
                        <div>{pajakPusat}</div>
                    </div>
                    <div className={style.divTotalPajak}>
                        <div>Pajak daerah</div>
                        <div>{pajakDaerah}</div>
                    </div>
                </div>
               
            </div>
        </div>
    );
};

export default OwnerPajak;
