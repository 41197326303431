import React, { useEffect, useRef, useState } from "react";
import style from "./style.module.css";
import LogoNew from "../../Photo/logoNew.png";
import InputForm from "../../Component/InputForm";
import SpinnerForm from "../../Component/SpinnerForm";
import Button from "../../Component/Button";
import LogoSilang from "../../Photo/logoSilang.png";

const PersonalTrainer = (props) => {
  const url = props.url;
  const inputRef = useRef(null);
  const [listNamaTrainer, setListNamaTrainer] = useState([]);
  const [id, setId] = useState("newForm");
  const [ubahDivKanan, setUbahDivKanan] = useState("divKananAbu");
  const [displayAlasan, setDisplayAlasan] = useState("notVisibleAlasan");
  const [keterangan, setKeterangan] = useState("");
  const [listDetailPesertaKiri, setListDetailPesertaKiri] = useState([]);
  const [listDetailKehadiranPeserta, setListDetailKehadiranPeserta] = useState([]);
  const [colorKeterangan, setColorKeterangan] = useState(
    "merahColorKeterangan"
  );
  const[containerDetailTrainerKanan,setContainerDetailTrainerKanan]=useState("notVisibleContainerDetailTrainerKanan");
  const [popUp, setPopUp] = useState("notVisiblePopUp");
  const [pelapis, setPelapis] = useState("notVisiblePelapis");
  const [namaTd, setNamaTd] = useState("");
  const [namaTdPeserta, setNamaTdPeserta] = useState("");
  const [namaPeserta, setNamaPeserta] = useState("");
  const [keteranganPopUp, setKeteranganPopUp] = useState("");
  const [valueInput, setValueInput] = useState("");
  const [boolFocus, setBoolFocus] = useState(true);

  const inputRef1 = useRef();
  const inputRef2 = useRef();
  const inputRef3 = useRef();
  const inputRef4 = useRef();
  const inputRef5 = useRef();
  const inputRef6 = useRef();
  const spinnerRef1 = useRef();
  const spinnerRef2 = useRef();
  const spinnerRef3 = useRef();
  const spinnerRef4 = useRef();
  // fungsi membersihkan isian dan spinner
  const RemoveIsianDanSpinner = () => {
    inputRef1.current.clearValue();
    inputRef2.current.clearValue();
    inputRef3.current.clearValue();
    inputRef4.current.clearValue();
    inputRef5.current.clearValue();
    inputRef6.current.clearValue();
    spinnerRef1.current.clearValue();
    spinnerRef2.current.clearValue();
    spinnerRef3.current.clearValue();
    spinnerRef4.current.clearValue();
  };
  // fungsi setelah klik kirim
  const ValKirim = (val) => {
    if (val === "kosong") {
      setKeterangan("Pengisian tidak lengkap");
      setColorKeterangan("merahColorKeterangan");
    } else {
      setListNamaTrainer(val);
      RemoveLS();
      setColorKeterangan("hitamColorKeterangan");

      setKeterangan("Data telah terkirim");
      setTimeout(() => {
        setKeterangan("");
      }, 3000);
      RemoveIsianDanSpinner();
    }
  };
  // fungsi val spinner
  const ValSpinner = (val) => {
    if (val === "muncul") {
      setDisplayAlasan("visibleAlasan");
    } else {
      setDisplayAlasan("notVisibleAlasan");
    }
  };
  // fungsi ambil data trainer
  async function AmbilDataTrainer() {
    await fetch(url + "/?op=getTrainerReader")
      .then((response) => response.json())
      .then((json) => {
        if (json !== null) {
          setListNamaTrainer(json);
        }
      });
  }
  // fungsi setelah klik hadir
  const KlikHadir = (
    namaPeserta,
    nomorHp,
    sisaPertemuan,
    berjalanPertemuan
  ) => {
    localStorage.setItem("namaPesertaTrainer", namaPeserta);
    localStorage.setItem("nomorHpPesertaTrainer", nomorHp);
    localStorage.setItem("sisaPertemuanPesertaTrainer", sisaPertemuan);
    localStorage.setItem("berjalanPertemuanPesertaTrainer", berjalanPertemuan);
    setBoolFocus(true);
    setPopUp("visiblePopUp");
    setPelapis("visiblePelapis");
    setKeteranganPopUp("");
    setNamaPeserta(namaPeserta);
    if (inputRef.current) {
      inputRef.current.focus();
    }

    // Set an interval to refocus the input field every 100 ms
    const interval = setInterval(() => {
      if (inputRef.current && boolFocus) {
        inputRef.current.focus();
      }
    }, 100);

    return () => clearInterval(interval);
  };
  // fungsi ketika sudah scan trainer
  const handleInputChange = (val) => {
    setValueInput(val);
    if (val.length === 10) {
      if (val === localStorage.getItem("rfidDetailTrainer")) {
        fetch(url + "/?op=updatePesertaTrainer", {
          method: "post",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body:
            "nama_peserta=" +
            localStorage.getItem("namaPesertaTrainer") +
            "&nomor_hp=" +
            localStorage.getItem("nomorHpPesertaTrainer") +
            "&nama_trainer=" +
            localStorage.getItem("namaDetailTrainer") +
            "&sisa_pertemuan=" +
            localStorage.getItem("sisaPertemuanPesertaTrainer") +
            "&berjalan_pertemuan=" +
            localStorage.getItem("berjalanPertemuanPesertaTrainer"),
        })
          .then((response) => response.json())
          .then((json) => {
            if (json !== null) {
              setValueInput("");
              setPopUp("notVisiblePopUp");
              setPelapis("notVisiblePelapis");
              setBoolFocus(false);
              AmbilDataTrainer();
              AmbilListDetailPesertaKiri(
                localStorage.getItem("namaDetailTrainer")
              );
            }
            setContainerDetailTrainerKanan("notVisibleContainerDetailTrainerKanan");
            setNamaTdPeserta("");
          });
      } else {
        setValueInput("");
        setKeteranganPopUp("RFID Card tidak sesuai");
      }
    }
  };
  //fungsi klik logo silang pop up
  const KlikLogoSilang = () => {
    setPopUp("notVisiblePopUp");
    setPelapis("notVisiblePelapis");
    setBoolFocus(false);
  };

  // fungsi klik td peserta untuk melihat kehadiran
  const KlikTdPeserta=(namaPeserta,namaTrainer)=>{
    
    setNamaTdPeserta(namaPeserta);
    fetch(url + "/?op=getDetailKehadiranPeserta", {
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body:
        "nama_peserta=" +
        namaPeserta +
        "&nama_trainer=" +
        namaTrainer 
    })
    .then((response)=>response.json())
    .then((json)=>{
      if(json!==null){
        setContainerDetailTrainerKanan("visibleContainerDetailTrainerKanan");
        setListDetailKehadiranPeserta(json);
      }else{
        setListDetailKehadiranPeserta([]);
      }
    })
  }

  // fungsi untuk input personal trainer
  const NewForm = () => {
    return (
      <div className={style.containerNewForm}>
        <div className={style.isiNewForm}>
          <div className={style.judulNewForm}>Form Personal Trainer</div>
          <div className={style.isian}>
            <InputForm
              ref={inputRef1}
              nama="Nama Peserta"
              id="namaPeserta"
              kosong="kosong"
            />
          </div>
          <div className={style.isian}>
            <InputForm ref={inputRef2} nama="Alamat" id="alamatPeserta" />
          </div>
          <div className={style.isian}>
            <InputForm
              ref={inputRef3}
              nama="Nomor HP"
              id="nomorHpPeserta"
              type="number"
            />
          </div>
          <div className={style.isian}>
            <InputForm
              ref={inputRef4}
              nama="Berat Badan"
              id="beratBadanPeserta"
              type="number"
            />
          </div>
          <div className={style.isian}>
            <InputForm ref={inputRef5} nama="Goal" id="goalPeserta" />
          </div>
          <div className={style.isianSpinner}>
            <SpinnerForm
              ref={spinnerRef1}
              ValSpinner={ValSpinner}
              nama="Paket Pertemuan"
              id="paketPertemuan"
            />
          </div>
          <div className={style.isianSpinner}>
            <SpinnerForm
              ref={spinnerRef2}
              ValSpinner={ValSpinner}
              nama="Status Pertemuan"
              id="statusPertemuan"
            />
          </div>
          <div className={style.isianSpinner}>
            <SpinnerForm
              ref={spinnerRef3}
              ValSpinner={ValSpinner}
              nama="Trainer"
              id="pilihanTrainer"
            />
          </div>
          <div className={style[displayAlasan]}>
            <InputForm
              ref={inputRef6}
              nama="Alasan memilih cewek"
              id="keterangan"
            />
          </div>
          <div className={style.isianSpinner}>
            <SpinnerForm
              ref={spinnerRef4}
              ValSpinner={ValSpinner}
              nama="Pembayaran"
              id="pembayaranTrainer"
            />
          </div>
          <div className={style[`${colorKeterangan}`]}>{keterangan}</div>
          <div className={style.divKirim}>
            <Button
              ValKirim={ValKirim}
              borderRadius="2vw"
              nama="Kirim"
              id="kirimPersonalTrainer"
            />
          </div>
          <div className={style.jedaAkhir}></div>
        </div>
      </div>
    );
  };

  // fungsi untuk lihat detail trainer
  const DetailTrainer = () => {
    return (
      <div className={style.containerDetailTrainer}>
        <div className={style.containerDetailTrainerKiri}>
          <div className={style.judulDetailTrainerKiri}>
            Detail Nama Peserta PT
          </div>
          <div className={style.isiDetailTrainerKiri}>
            <table className={style.tableDetailTrainerKiri}>
              <thead>
                <tr className={style.trSticky}>
                  <th>Nama</th>
                  <th>Status</th>
                  <th>Paket</th>
                  <th>Berjalan</th>
                  <th>Sisa</th>
                  <th>Absensi</th>
                </tr>
              </thead>
              <tbody>
                {listDetailPesertaKiri.map((item, index) => (
                  <tr key={index}>
                    <td className={style.tdKlik} style={{color:item.nama_peserta === namaTdPeserta?"blue":"black"}} 
                    onClick={()=>KlikTdPeserta(item.nama_peserta,item.nama_trainer)}>{item.nama_peserta}</td>
                    <td>{item.status_pertemuan}</td>
                    <td>{item.paket_pertemuan}</td>
                    <td>{item.berjalan_pertemuan}</td>
                    <td>{item.sisa_pertemuan}</td>
                    <td>
                      <button
                        className={style.buttonHadir}
                        onClick={() =>
                          KlikHadir(
                            item.nama_peserta,
                            item.nomor_hp,
                            item.sisa_pertemuan,
                            item.berjalan_pertemuan
                          )
                        }
                      >
                        Hadir
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className={style[`${containerDetailTrainerKanan}`]}>
        <div className={style.judulDetailTrainerKiri}>
            Kehadiran Peserta Training
          </div>
          <div className={style.isiDetailTrainerKiri}>
            <table className={style.tableDetailTrainerKiri}>
              <thead>
                <tr className={style.trSticky}>
                  <th>Nama</th>
                  <th>Kehadiran</th>
                
                </tr>
              </thead>
              <tbody>
                {listDetailKehadiranPeserta.map((item, index) => (
                  <tr key={index}>
                    <td>{item.nama_peserta}</td>
                    <td>{item.timestamp}</td>
                  
                    
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };
  // fungsi klik new form
  const KlikNew = () => {
    setNamaTd("");
    setKeterangan("");
    setId("newForm");
    setUbahDivKanan("divKananAbu");
  };
  // fungsi klik tabel data trainer
  const KlikTd = (val, rfid) => {
    localStorage.setItem("rfidDetailTrainer", rfid);
    localStorage.setItem("namaDetailTrainer", val);
    setNamaTd(val);
    setId("");
    setUbahDivKanan("divKananWhite");
    AmbilListDetailPesertaKiri(val);
    setContainerDetailTrainerKanan("notVisibleContainerDetailTrainerKanan");
    setNamaTdPeserta("");
  };
  //fungdsi ambil list detail peserta kiri
  const AmbilListDetailPesertaKiri = (val) => {
    fetch(url + "/?op=getListDetailPesertaKiri", {
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: "nama_trainer=" + val,
    })
      .then((response) => response.json())
      .then((json) => {
        if (json !== null) {
          setListDetailPesertaKiri(json);
        } else {
          setListDetailPesertaKiri([]);
        }
      });
  };
  // fungsi untuk remove ls
  const RemoveLS = () => {
    localStorage.removeItem("namaPeserta");
    localStorage.removeItem("alamatPeserta");
    localStorage.removeItem("nomorHpPeserta");
    localStorage.removeItem("beratBadanPeserta");
    localStorage.removeItem("goalPeserta");
    localStorage.removeItem("paketPertemuan");
    localStorage.removeItem("statusPertemuan");
    localStorage.removeItem("pilihanTrainer");
    localStorage.removeItem("keterangan");
    localStorage.removeItem("rfidDetailTrainer");
    localStorage.removeItem("namaPesertaTrainer");
    localStorage.removeItem("nomorHpPesertaTrainer");
    localStorage.removeItem("sisaPertemuanPesertaTrainer");
    localStorage.removeItem("berjalanPertemuanPesertaTrainer");
    localStorage.removeItem("namaDetailTrainer");
    localStorage.removeItem("pembayaranTrainer");
  };
  // useEffect
  useEffect(() => {
    AmbilDataTrainer();
    RemoveLS();
  }, []);

  return (
    <div className={style.containerAll}>
      <div className={style[`${pelapis}`]}></div>
      <div className={style[`${popUp}`]}>
        <input
          className={style.inputPopUp}
          value={valueInput}
          onChange={(e) => handleInputChange(e.target.value)}
          ref={inputRef}
        />
        <div className={style.dialogPopUp}>
          <div className={style.divJudulPopUp}>
            Validasi Kehadiran
            <img
              className={style.logoSilang}
              src={LogoSilang}
              onClick={() => KlikLogoSilang()}
            />
          </div>
          <div className={style.divNamaPeserta}>{namaPeserta}</div>
          <div className={style.divScan}>Scan Id Trainer</div>
          <div className={style.divKeteranganPopUp}>{keteranganPopUp}</div>
        </div>
      </div>
      <div className={style.divKiri}>
        <div className={style.judulTrainer}>
          <img src={LogoNew} onClick={() => KlikNew()} />
          Trainer
        </div>
        <table className={style.table}>
          <thead>
            <tr>
              <th>Nama</th>
              <th>User Aktif</th>
        
            </tr>
          </thead>
          <tbody>
            {listNamaTrainer.map((item, index) => (
              <tr key={index}>
                <td
                  onClick={() => KlikTd(item.nama, item.rfid)}
                  style={{ color: item.nama === namaTd ? "blue" : "black" }}
                  className={style.tdKlik}
                >
                  {item.nama}
                </td>
                <td>{item.user}</td>
              
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={style[`${ubahDivKanan}`]}>
        {id == "newForm" ? NewForm() : DetailTrainer()}
      </div>
    </div>
  );
};

export default PersonalTrainer;
