import React, { useEffect, useRef, useState } from "react";
import PilihanLogin from "../../Component/PilihanLogin";
import style from "./style.module.css";
import LogoGym from "../../Photo/logoGym.png";
import LogoOwner from "../../Photo/logoOwner.png";
import Button from "../../Component/Button";
import { useNavigate } from "react-router-dom";

const Home = (props) => {
  const navigate = useNavigate();
  const [idPilihan, setIdPilihan] = useState("");
  const [keterangan, setKeterangan] = useState("");
  //function setelaj klik pilihan
  const ValPilihan = (val) => {
    setIdPilihan(val);
  };
  //function jika tikda pilih akun setelah klik kirim
  const ValKirim = (val) => {
    setKeterangan(val);
  };
  useEffect(() => {
    let loginSebagai = localStorage.getItem("loginSebagai");
    if (loginSebagai === null) {
      navigate("/");
    } else if (loginSebagai === "y") {
      navigate("/gym");
    } else if (loginSebagai === "0wr") {
      navigate("/owner");
    }
  }, []);
  return (
    <div className={style.containerAll}>
      <div className={style.judul}>KEKE's GYM</div>
      <div className={style.isi}>
        <div className={style.divLogin}>
          <div className={style.judulDivLogin}>Login</div>
          <div className={style.pilihanDivLogin}>
            <div
              style={{
                border:
                  idPilihan === "loginGym"
                    ? "solid 1px #2f69fd"
                    : "solid 1px rgb(230, 230, 230)",
              }}
              className={style.divPilihanKaryawan}
            >
              <PilihanLogin
                ValPilihan={ValPilihan}
                nama="Gym"
                id="loginGym"
                gambar={LogoGym}
              />
            </div>

            <div
              style={{
                border:
                  idPilihan === "loginOwner"
                    ? "solid 1px #2f69fd"
                    : "solid 1px rgb(230, 230, 230)",
              }}
              className={style.divPilihanOwner}
            >
              <PilihanLogin
                ValPilihan={ValPilihan}
                nama="Owner"
                id="loginOwner"
                gambar={LogoOwner}
              />
            </div>
          </div>
          <div className={style.divPassword}>
            <input
              onChange={(e) =>
                localStorage.setItem("passwordLogin", e.target.value)
              }
              placeholder="Password"
            />
            <div className={style.divKirim}>
              <Button ValKirim={ValKirim} nama="Kirim" id="kirimLogin" />
            </div>
          </div>
          <div className={style.divKeterangan}>{keterangan}</div>
        </div>
      </div>
    </div>
  );
};

export default Home;
