import React, { useState, useEffect } from "react";
import InputForm from "../../Component/InputForm";
import SpinnerForm from "../../Component/SpinnerForm";
import Button from "../../Component/Button";
import style from "./style.module.css";
import LogoKekes from "../../Photo/logoKekes2.png";
import { useNavigate } from "react-router-dom";

const MemberBaruGym = (props) => {
    const navigate = useNavigate();
    const url = props.url;
    const [tulisanKeterangan, setTulisanKeterangan] = useState("");
    const [displayAfiliator, setDisplayAfiliator] = useState("notVisibleAfiliator");
    const [displayWaktu, setDisplayWaktu] = useState("notVisibleWaktu");
    const [displayGender, setDisplayGender] = useState("notVisibleGender");
    const [valueNextExpired, setValueNextExpired] = useState("");

    // function Val Spinner
    const ValSpinner = (val) => {
     
        if(val ==="muncul"){
       
        setDisplayWaktu("visibleWaktu");
        }else if(val === "sembunyi"){
           
        setDisplayWaktu("notVisibleWaktu");
        }
        else{
            setValueNextExpired(val);
        }
    };
     // function Val Spinner Gender
     const ValSpinnerGender = (val) => {
     
        if(val ==="muncul"){
       
        setDisplayGender("visibleGender");
        }else{
           
        setDisplayGender("notVisibleGender");
        }
    };

    // function setelah klik kirim
    const ValKirim = (val) => {
        if (val === "kosong") {
            setTulisanKeterangan("Data tidak lengkap");
        }else{
            setTulisanKeterangan("");
            
        }
    };
// Klik Logo 
const KlikLogo = ()=>{
navigate("/gym/attendance");
localStorage.removeItem("rfidVisitorGym");
Remove();
}
// function untuk remove
const Remove =()=>{
    localStorage.removeItem("namaMemberGym");
    localStorage.removeItem("alamatMemberGym");
    localStorage.removeItem("nomorMemberGym");
    localStorage.removeItem("waktuMemberGym");
    localStorage.removeItem("statusMemberGym");
    localStorage.removeItem("pembayaranGym");
    
}
    //useEffect
    useEffect(()=>{
    Remove();
    
        

    },[])

    return (
        <div className={style.containerAll}>
            <div className={style.judul}>
                New Member
                <div onClick={()=>KlikLogo()}>
                    <img src={LogoKekes}/>
                </div>
            </div>
            <div className={style.isi}>
                <div className={style.kotakForm}>
                    <div className={style.judulForm}>
                        Buat Member
                    </div>
                    <div className={style.isiForm}>
                        <div className={style.containerInputBiasa}>
                        <div className={style.inputBiasa}>
                            <InputForm nama="Nama" id="namaMemberGym" />
                        </div>
                        <div className={style.inputBiasa}>
                            <InputForm nama="Alamat" id="alamatMemberGym" />
                        </div>
                        <div className={style.inputBiasa}>
                            <InputForm type="number" nama="Nomor Handphone" id="nomorMemberGym" />
                        </div>
                        <div className={style.inputBiasa}>
                            <SpinnerForm ValSpinnerGender={ValSpinnerGender} ValSpinner={ValSpinner} nama="Status" id="statusMemberGym" />
                        </div>

                        <div className={style[displayWaktu]}>
                            <SpinnerForm ValSpinner={ValSpinner} nama="Waktu Member" id="waktuMemberGym" />
                        </div>
                        <div className={style[displayWaktu]}>
                            <InputForm nextExpired={valueNextExpired}  nama="Expired Member" id="nextExpiredGym" />
                        </div>
                        <div className={style[displayWaktu]}>
                            <SpinnerForm nama="Pembayaran" id="pembayaranGym" />
                        </div>
                        <div className={style[displayGender]}>
                            <SpinnerForm nama="Gender" id="genderGym" />
                        </div>
                        </div>
                        <div className={style.keterangan}>
                            {tulisanKeterangan}
                        </div>
                        <div className={style.divKirim}>
                            <Button ValKirim={ValKirim} borderRadius="2vw" nama="Kirim" id="kirimMemberGymBaru" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MemberBaruGym;
