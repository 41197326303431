import React, { useState } from "react";
import { useEffect } from "react";
import style from "./style.module.css";
import PanahBawahPolos from "../../Photo/panahBawahPolos.png";
import PanahBawahIsi from "../../Photo/panahBawahIsi.png";
const RekapanGym = (props) => {
  const url = props.url;
  const [dataBarang, setDataBarang] = useState([]);

  const [dataFreelance, setDataFreelance] = useState([]);
  const [divInput, setDivInput] = useState("notVisibleDivInput");
  const [valueNamaBarang, setValueNamaBarang] = useState("");
  const [valueHargaBarang, setValueHargaBarang] = useState("");
  const [valueQuantityBarang, setValueQuantityBarang] = useState("");
  const [totalIncomeInventory, setTotalIncomeInventory] = useState(0);
  const [totalIncomeFreelance, setTotalIncomeFreelance] = useState(0);
  const [totalOutcomeInventory, setTotalOutcomeInventory] = useState(0);
  const [jumlahDataFreelance, setJumlahDataFreelance] = useState(0);
  const [nilaiDate, setNilaiDate] = useState("");
  const [logoPanah, setLogoPanah] = useState(PanahBawahPolos);
  const [divThKeterangan, setDivThKeterangan] = useState("divThKeteranganNone");
  const [jumlahDataFreelanceLS, setJumlahDataFreelanceLS] = useState(0);
  const [cash, setCash] = useState(0);
  const [nonCash, setNonCash] = useState(0);
  //
  const ChangeDatePhp = (val) => {
    fetch(url + "/?op=getDataAllHistoryPengeluaranInventoryGymKaryawan", {
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: "date=" + val,
    })
      .then((response) => response.json())
      .then((json) => {
        if (json !== null) {
        
          localStorage.setItem("dataBarang", JSON.stringify(json));
          setDataBarang(json);

          let outcome = 0;
          let income = 0;
          let incomeCash = 0;
          let incomeNonCash = 0;
          if (json.filter((item) => item.keterangan === "income").length > 0) {
            let jsonIncome = json.filter(
              (item) => item.keterangan === "income"
            );
            let jsonCash=json.filter((item)=>item.metode === 'Cash');
            let jsonNonCash=json.filter((item)=>item.metode === 'Non Cash');
            for (let i = 0; i < jsonIncome.length; i++) {
              income = parseInt(income) + parseInt(jsonIncome[i].total);
            }
            for (let i = 0; i < jsonCash.length; i++) {
              incomeCash = parseInt(incomeCash) + parseInt(jsonCash[i].total);
            }
            for (let i = 0; i < jsonNonCash.length; i++) {
              incomeNonCash = parseInt(incomeNonCash) + parseInt(jsonNonCash[i].total);
            }
            setTotalIncomeInventory(income);
            setCash(incomeCash);
            setNonCash(incomeNonCash);
          } else {
            setTotalIncomeInventory(0);
            setCash(0);
            setNonCash(0);
          }
          if (json.filter((item) => item.keterangan === "outcome").length > 0) {
            let jsonOutcome = json.filter(
              (item) => item.keterangan === "outcome"
            );
            for (let i = 0; i < jsonOutcome.length; i++) {
              outcome = parseInt(outcome) + parseInt(jsonOutcome[i].total);
            }
            setTotalOutcomeInventory(outcome);
          } else {
            setTotalOutcomeInventory(0);
          }
        } else {
          localStorage.setItem("dataBarang", JSON.stringify([]));
          setDataBarang([]);
          setTotalIncomeInventory(0);
          setTotalOutcomeInventory(0);
          setCash(0);
          setNonCash(0);
        
        }
      });
  };
  //
  async function AmbilDataBarang(val) {
    await fetch(url + "/?op=getDataAllHistoryPengeluaranInventoryGymKaryawan")
      .then((response) => response.json())
      .then((json) => {
        if (json !== null) {
          localStorage.setItem("dataBarang", JSON.stringify(json));
          setDataBarang(json);

          let outcome = 0;
          let income = 0;
          let incomeCash = 0;
          let incomeNonCash = 0;
          if (json.filter((item) => item.keterangan === "income").length > 0) {
            let jsonIncome = json.filter(
              (item) => item.keterangan === "income"
            );
            let jsonCash=json.filter((item)=>item.metode === 'Cash');
            let jsonNonCash=json.filter((item)=>item.metode === 'Non Cash');
            for (let i = 0; i < jsonIncome.length; i++) {
              income = parseInt(income) + parseInt(jsonIncome[i].total);
            }
            for (let i = 0; i < jsonCash.length; i++) {
              incomeCash = parseInt(incomeCash) + parseInt(jsonCash[i].total);
            }
            for (let i = 0; i < jsonNonCash.length; i++) {
              incomeNonCash = parseInt(incomeNonCash) + parseInt(jsonNonCash[i].total);
            }
            setTotalIncomeInventory(income);
            setCash(incomeCash);
            setNonCash(incomeNonCash);
          } else {
            setTotalIncomeInventory(0);
            setCash(0);
            setNonCash(0);
          }
          if (json.filter((item) => item.keterangan === "outcome").length > 0) {
            let jsonOutcome = json.filter(
              (item) => item.keterangan === "outcome"
            );
            for (let i = 0; i < jsonOutcome.length; i++) {
              outcome = parseInt(outcome) + parseInt(jsonOutcome[i].total);
            }
       
            setTotalOutcomeInventory(outcome);

          } else {
            setTotalOutcomeInventory(0);
          }
        } else {
          setDataBarang([]);
          setTotalIncomeInventory(0);
          setTotalOutcomeInventory(0);
        
        }
      });
  }

  //
  const KlikTr = (val) => {
    setDivInput("visibleDivInput");
    setValueNamaBarang(val.nama_barang);
    setValueHargaBarang(val.harga_barang);
    setValueQuantityBarang(val.stok_barang);
  };

  //
  const ChangeDate = (val) => {
    setNilaiDate(val);
    ChangeDatePhp(val);
  };

  useEffect(() => {
    AmbilDataBarang();
  }, []);
  return (
    <div className={style.containerAll}>
      <div className={style.judul}>
        <div className={style.tulisanJudul}>Rekapan Inventory Gym</div>
        <div className={style.date}>
          <input
            type="date"
            value={nilaiDate}
            onChange={(e) => ChangeDate(e.target.value)}
          />
        </div>
      </div>

      <div className={style.divTable}>
        <table className={style.table}>
          <thead>
            <tr className={style.trSticky}>
              <th>Nama Barang</th>
              <th>Jumlah</th>
              <th>Total Harga</th>
              <th>Metode</th>
              <th className={style.thKeterangan}>
                Keterangan
                <img
                  onClick={() => {
                    if (divThKeterangan === "divThKeteranganVisible") {
                      setDivThKeterangan("divThKeteranganNone");
                    } else {
                      setDivThKeterangan("divThKeteranganVisible");
                    }
                  }}
                  src={logoPanah}
                />
                <div className={style[`${divThKeterangan}`]}>
                  <div
                    onClick={() => {
                      setDataBarang(
                        JSON.parse(localStorage.getItem("dataBarang"))
                      );
                      setLogoPanah(PanahBawahPolos);
                      setDivThKeterangan("divThKeteranganNone");
                    }}
                  >
                    all
                  </div>
                  <div
                    onClick={() => {
                      setDataBarang(
                        JSON.parse(localStorage.getItem("dataBarang")).filter(
                          (item) => item.keterangan === "outcome"
                        )
                      );
                      setLogoPanah(PanahBawahIsi);
                      setDivThKeterangan("divThKeteranganNone");
                      setJumlahDataFreelanceLS(0);
                    }}
                  >
                    outcome
                  </div>
                  <div
                    onClick={() => {
                      setDataBarang(
                        JSON.parse(localStorage.getItem("dataBarang")).filter(
                          (item) => item.keterangan === "income"
                        )
                      );
                      setLogoPanah(PanahBawahIsi);
                      setDivThKeterangan("divThKeteranganNone");
                    }}
                  >
                    income
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {dataBarang.map((val, index) => (
              <tr
                style={{ cursor: "pointer" }}
                onClick={() => KlikTr(val)}
                key={index}
              >
                <td> {val.nama_barang}</td>
                <td>{val.out_barang}</td>
                <td>{val.total}</td>
                <td>{val.metode}</td>
                <td>{val.keterangan}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={style.divTotalRekap}>
        <div>total income : {totalIncomeInventory} {"( Cash : "}{cash}{", Non Cash : "}{nonCash}{" )"}</div>
        <div>total outcome : {totalOutcomeInventory}</div>
        <div>
          saldo :{" "}
          {totalIncomeInventory - totalOutcomeInventory}
        </div>
      </div>
    </div>
  );
};
export default RekapanGym;
