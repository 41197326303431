import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import style from "./style.module.css";
import LogoKekes from "../../Photo/logoKekes2.png";

const InventoryGym = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [tulisanJudul, setTulisanJudul] = useState("Pengeluaran");
  const [id, setId] = useState("pengeluaran");
  // function Klik link judul
  const KlikLink = (id) => {
    setId(id);
    switch (id) {
      case "pengeluaran":
        setTulisanJudul("Pengeluaran");
        navigate("/gym/inventory");
        break;
      
      case "rekapan":
        setTulisanJudul("Rekapan");
        navigate("/gym/inventory/rekapan");
        break;
      
        case "personalTrainer":
            setTulisanJudul("Personal Trainer");
            navigate("/gym/inventory/personalTrainer");
            break;
      default:
        break;
    }
  };
  //
  useEffect(() => {
    const path = location.pathname.split("/").pop();
    switch (path) {
     
      case "rekapan":
        setId("rekapan");
        setTulisanJudul("Rekapan");
        break;

      case "pengeluaran":
        setId("pengeluaran");
        setTulisanJudul("Pengeluaran");
        break;
        case "personalTrainer":
            setId("personalTrainer");
            setTulisanJudul("Personal Trainer");
            break;
      default:
        break;
    }
  }, [location.pathname]);

  return (
    <div className={style.containerAll}>
      <div className={style.divJudul}>
        <div className={style.divTulisanJudul}>
          <div>
            <img onClick={() => navigate("/gym")} src={LogoKekes} />
            </div>
          
          {tulisanJudul} Inventory Gym
        </div>
        <div className={style.divLinkJudul}>
          <div
            style={{
              borderBottom: id === "pengeluaran" ? "solid 1px #2f69fd" : "",
            }}
            onClick={() => KlikLink("pengeluaran")}
            className={style.divLink}
          >
            Pengeluaran
          </div>
          
          <div
            style={{
              borderBottom: id === "rekapan" ? "solid 1px #2f69fd" : "",
            }}
            onClick={() => KlikLink("rekapan")}
            className={style.divLink}
          >
            Rekapan
          </div>
          <div
            style={{
              borderBottom: id === "personalTrainer" ? "solid 1px #2f69fd" : "",
            }}
            onClick={() => KlikLink("personalTrainer")}
            className={style.divLink}
          >
            Personal Trainer
          </div>
        </div>
      </div>

      <Outlet />
    </div>
  );
};
export default InventoryGym;
