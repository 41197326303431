import React, { useState, useEffect, useRef } from 'react';
import style from "./style.module.css";
import SpinnerForm from "../../Component/SpinnerForm";
import MenuTable from '../../Component/MenuTable';
import Receipt from '../../Component/Receipt';
import LogoSilang from "../../Photo/logoSilang.png";

const PengeluaranGym = (props) => {
    const url = props.url;
    const [keterangan,setKeterangan]=useState("");
    const[popUp,setPopUp]=useState("notVisiblePopUp");
    const[lapisan,setLapisan]=useState("notVisibleLapisan");
    const inputRef = useRef(null);
    const [items, setItems] = useState([]);
    const [cart, setCart] = useState(() => {
        const savedCart = localStorage.getItem('cart');
        return savedCart ? JSON.parse(savedCart) : [];
    });
    const spinnerRef1 = useRef();
    useEffect(() => {
        fetchItems();
        localStorage.removeItem("pembayaranKasir");
    }, []);

    const fetchItems = async () => {
        try {
            const response = await fetch(`${url}/?op=getDataInventoryGym`);
            const data = await response.json();
            setItems(data);
        } catch (error) {
            console.error('Error fetching items:', error);
        }
    };

    useEffect(() => {
        localStorage.setItem('cart', JSON.stringify(cart));
    }, [cart]);

    const updateCart = (name, price, quantity) => {
        setCart(prevCart => {
            const itemIndex = prevCart.findIndex(item => item.name === name);
            if (itemIndex >= 0) {
                const updatedCart = [...prevCart];
                if (quantity > 0) {
                    updatedCart[itemIndex] = { ...updatedCart[itemIndex], quantity, total: price * quantity };
                } else {
                    updatedCart.splice(itemIndex, 1);
                }
                return updatedCart;
            } else {
                return [...prevCart, { name, price, quantity, total: price * quantity }];
            }
        });
    };

    const handleCheckout = async () => {
       setLapisan("visibleLapisan");
       setPopUp("visiblePopUp");
       localStorage.removeItem("pembayaranKasir");
       setKeterangan("");
    };
    const InputData = async (val) => {
        try {
            await fetch(`${url}/?op=inputPengeluaranDataInventoryGym`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(val)
            }
            );
            // Memasukkan data transaksi ke dalam tabel history_pengeluaran_inventory_gym
        await fetch(`${url}/?op=inputHistoryPengeluaranGym`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(val)
        });
            setCart([]);
            fetchItems();
            setLapisan("notVisibleLapisan");
            setPopUp("notVisiblePopUp");
            spinnerRef1.current.clearValue();
            localStorage.removeItem("pembayaranKasir");
        } catch (error) {
            console.error('Error during checkout:', error);
        }
    };
    
// function Klik Kirim
const KlikKirim=()=>{
    if((localStorage.getItem("pembayaranKasir") === null)||
    (localStorage.getItem("pembayaranKasir") === 'Pilih')){
setKeterangan("Pilih dahulu metodenya");
    }
    else if (localStorage.getItem("pembayaranKasir") === 'Cash') {
        setKeterangan("");
        let cartMetode = JSON.parse(localStorage.getItem('cart')) || [];
        cartMetode = cartMetode.map(item => ({
          ...item,
          metode: "Cash"
        }));
        localStorage.setItem('cart', JSON.stringify(cartMetode));
 

       InputData(cartMetode);
}else if(localStorage.getItem("pembayaranKasir") === 'Non Cash'){
    setKeterangan("");
    let cartMetode = JSON.parse(localStorage.getItem('cart')) || [];
    cartMetode = cartMetode.map(item => ({
      ...item,
      metode: "Non Cash"
    }));
    localStorage.setItem('cart', JSON.stringify(cartMetode));

   
    InputData(cartMetode);
}
}
// function Klik Logo Silang
const KlikLogoSilang=()=>{
    setLapisan("notVisibleLapisan");
    setPopUp("notVisiblePopUp");
    spinnerRef1.current.clearValue();
    localStorage.removeItem("pembayaranKasir");
}
    return (
        <div className={style.containerAll}>
            <div className={style[`${lapisan}`]}></div>
            <div className={style[`${popUp}`]}>
                <div className={style.judulPopUp}>
Metode Pembayaran
<img src={LogoSilang} onClick={()=>KlikLogoSilang()}/>
                </div>
                <div className={style.spinnerPopUp}>
                <SpinnerForm ref={spinnerRef1} nama="Pembayaran" id="pembayaranKasir" />
                </div>
                <div className={style.keteranganPopUp}>
{keterangan}
                </div>
                <button className={style.buttonKirim} onClick={()=>KlikKirim()}>
                    Kirim
                </button>
            </div>
            <div className={style.divMenuTable}>
                <MenuTable items={items} updateCart={updateCart} cart={cart} />
            </div>
            <div className={style.divReceipt}>
                <Receipt handleCheckout={handleCheckout} cart={cart} />
            </div>
        </div>
    );
};

export default PengeluaranGym;
