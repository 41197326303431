import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import style from "./style.module.css";
import PanahBawahPolos from "../../Photo/panahBawahPolos.png";
import PanahBawahIsi from "../../Photo/panahBawahIsi.png";
import LogoPanah from "../../Photo/panah.png";

const OwnerCashFlow = (props) => {
  const url = props.url;
  const [nilaiTotalSaldo, setNilaiTotalSaldo] = useState("");
  const [dataBarangZumba, setDataBarangZumba] = useState([]);
  const [dataBarang, setDataBarang] = useState([]);
  const [dataFreelance, setDataFreelance] = useState([]);
  const [dataFreelanceZumba, setDataFreelanceZumba] = useState([]);
  const [divInput, setDivInput] = useState("notVisibleDivInput");
  const [valueNamaBarang, setValueNamaBarang] = useState("");
  const [valueHargaBarang, setValueHargaBarang] = useState("");
  const [valueQuantityBarang, setValueQuantityBarang] = useState("");
  const [totalIncomeInventory, setTotalIncomeInventory] = useState(0);
  const [totalIncomeFreelance, setTotalIncomeFreelance] = useState(0);
  const [totalOutcomeInventory, setTotalOutcomeInventory] = useState(0);
  const [totalIncomeInventoryZumba, setTotalIncomeInventoryZumba] = useState(0);
  const [totalIncomeFreelanceZumba, setTotalIncomeFreelanceZumba] = useState(0);
  const [totalOutcomeInventoryZumba, setTotalOutcomeInventoryZumba] =
    useState(0);
  const [jumlahDataFreelance, setJumlahDataFreelance] = useState(0);
  const [jumlahDataFreelanceZumba, setJumlahDataFreelanceZumba] = useState(0);
  const [nilaiDate, setNilaiDate] = useState("");
  const [logoPanah,setLogoPanah]=useState(PanahBawahPolos);
const[divThKeterangan,setDivThKeterangan]=useState("divThKeteranganNone");
const[jumlahDataFreelanceLS,setJumlahDataFreelanceLS]=useState(0);  
//
  const ChangeDatePhp = (val) => {
    fetch(url + "/?op=getDataAllHistoryPengeluaranInventoryGym", {
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: "dateStart=" +
      localStorage.getItem("dateOwnerCashFlowStart") +
      "&dateFinish=" +
      localStorage.getItem("dateOwnerCashFlowFinish"),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json !== null) {
          localStorage.setItem("dataBarang",JSON.stringify(json));
          if(localStorage.getItem("filterStatusDataBarang")==="all"){
          setDataBarang(json);
        }
          let outcome = 0;
          let income = 0;
          let jsonIncome = json.filter((item) => item.keterangan === "income");
          let jsonOutcome = json.filter(
            (item) => item.keterangan === "outcome"
          );
          for (let i = 0; i < jsonIncome.length; i++) {
            income = parseInt(income) + parseInt(jsonIncome[i].total);
          }
          for (let i = 0; i < jsonOutcome.length; i++) {
            outcome = parseInt(outcome) + parseInt(jsonOutcome[i].total);
          }

          setTotalIncomeInventory(income);
          setTotalOutcomeInventory(outcome);
        } else {
          localStorage.setItem("dataBarang",JSON.stringify([]));
          setDataBarang([]);
          setTotalIncomeInventory(0);
          setTotalOutcomeInventory(0);
        }
      });
    
  };
  

  //
  async function AmbilDataBarang(val) {
    await fetch(url + "/?op=getDataAllHistoryPengeluaranInventoryGym")
      .then((response) => response.json())
      .then((json) => {
        if (json !== null) {
          localStorage.setItem("dataBarang",JSON.stringify(json));
          setDataBarang(json);

          let outcome = 0;
          let income = 0;
          let jsonIncome = json.filter((item) => item.keterangan === "income");
          let jsonOutcome = json.filter(
            (item) => item.keterangan === "outcome"
          );
          for (let i = 0; i < jsonIncome.length; i++) {
            income = parseInt(income) + parseInt(jsonIncome[i].total);
          }
          for (let i = 0; i < jsonOutcome.length; i++) {
            outcome = parseInt(outcome) + parseInt(jsonOutcome[i].total);
          }

          setTotalIncomeInventory(income);
          setTotalOutcomeInventory(outcome);
        } else {
          setDataBarang([]);
          setTotalIncomeInventory(0);
          setTotalOutcomeInventory(0);
        }
      });
  }

  
  //
  const KlikTabel = () => {};
  //
  const ChangeDate = (val,id) => {
    switch(id){
      case "start":
        localStorage.setItem("dateOwnerCashFlowStart",val);
        setNilaiDate(val);
    ChangeDatePhp(val);
        break;
      case "finish":
        localStorage.setItem("dateOwnerCashFlowFinish",val);
        setNilaiDate(val);
    ChangeDatePhp(val);
        break;
      default:
        break;
    }
    localStorage.setItem("dateOwnerCashFlow",val);
    
   
  };
  //
  useEffect(() => {
    
    localStorage.setItem("dateOwnerCashFlowStart","");
    localStorage.setItem("dateOwnerCashFlowFinish","");
    localStorage.setItem("filterStatusDataBarang","all");
    AmbilDataBarang();
  
    
  }, []);
  useEffect(()=>{
      // Set an interval to refocus the input field every 100 ms
      const interval = setInterval(() => {
        ChangeDatePhp();
      
      
    }, 5000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
}, []);
  
  return (
    <div className={style.containerAll}>
      <div className={style.containerKiri}>
        <div className={style.date}>
        <input type="date" onChange={(e) => ChangeDate(e.target.value, "start")} />
                <div className={style.divLogoPanah}>
                    <img src={LogoPanah} alt="logo_panah" />
                </div>
                <input type="date" onChange={(e) => ChangeDate(e.target.value, "finish")} />
        </div>
        <div className={style.tulisanCashFlow}>Total Cash Flow</div>
        <div className={style.tableCashFlow}>
          <table className={style.table}>
            <thead>
              <tr
                onClick={() => {
                  KlikTabel();
                }}
              >
                <th>Item</th>
                <th>Gym</th>
              
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Pemasukan</td>
                <td>{totalIncomeInventory}</td>
              
              </tr>
              <tr>
                <td>Pengeluaran</td>
                <td>{totalOutcomeInventory}</td>
        
              </tr>
              <tr>
                <td>Saldo</td>
                <td>
                  {totalIncomeInventory -
                    totalOutcomeInventory}
                </td>
               
              </tr>
            </tbody>
          </table>
        </div>
        <div className={style.divTotalSaldo}>
          <div>Total Saldo</div>
          <div>
            {" "}
            {totalIncomeInventory  -
              totalOutcomeInventory }
          </div>
        </div>
      </div>
      <div className={style.containerKanan}>
        <div className={style.containerKananPemasukan}>
          <div className={style.judulDetailGym}>Detail Cash Flow Gym</div>
          <div className={style.isiDetailGym}>
            <table className={style.tableGym}>
              <thead>
                <tr className={style.trSticky}>
                  <th>Timestamp</th>
                  <th>nama</th>
                  <th>jumlah</th>
                  <th>Total</th>
                  <th className={style.thKeterangan}>status
                  <img onClick={()=>{
    if(divThKeterangan === "divThKeteranganVisible"){
      setDivThKeterangan("divThKeteranganNone");
    }else{
      setDivThKeterangan("divThKeteranganVisible");
    }
  }} src={logoPanah} />
  <div className={style[`${divThKeterangan}`]}>
    <div onClick={()=>{
      setDataBarang(JSON.parse(localStorage.getItem("dataBarang")));
      setLogoPanah(PanahBawahPolos);
      setDivThKeterangan("divThKeteranganNone");
      localStorage.setItem("filterStatusDataBarang","all");
      }}>all</div>
    <div onClick={()=>{
      setDataBarang(JSON.parse(localStorage.getItem("dataBarang")).filter((item)=>item.keterangan === "outcome"));
      setLogoPanah(PanahBawahIsi);
      setDivThKeterangan("divThKeteranganNone");
      localStorage.setItem("filterStatusDataBarang","outcome");
      }}>outcome</div>
    <div onClick={()=>{
      setDataBarang(JSON.parse(localStorage.getItem("dataBarang")).filter((item)=>item.keterangan === "income"));
      setLogoPanah(PanahBawahIsi);
      setDivThKeterangan("divThKeteranganNone");
 
      localStorage.setItem("filterStatusDataBarang","income");
      }}>income</div>
  </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataBarang.map((val, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}> {val.timestamp}</td>
                    <td style={{ textAlign: "center" }}> {val.nama_barang}</td>
                    <td style={{ textAlign: "center" }}>{val.out_barang}</td>
                    <td style={{ textAlign: "center" }}>{val.total}</td>
                    <td style={{ textAlign: "center" }}>{val.keterangan}</td>
                  </tr>
                ))}
                {dataFreelance.map((val, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}> {val.timestamp}</td>
                    <td style={{ textAlign: "center" }}> freelance</td>
                    <td style={{ textAlign: "center" }}>1</td>
                    <td style={{ textAlign: "center" }}>30000</td>
                    <td style={{ textAlign: "center" }}>income</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
       
      </div>
    </div>
  );
  
};
export default OwnerCashFlow;


