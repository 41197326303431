import React, { useState } from "react";
import { useEffect } from "react";
import style from "./style.module.css";

const OwnerInOut = (props) => {
  const url = props.url;
  const [dataBarang, setDataBarang] = useState([]);
  const [divInput, setDivInput] = useState("notVisibleDivInput");
  const [valueNamaBarang, setValueNamaBarang] = useState("");
  const [valueHargaBarang, setValueHargaBarang] = useState("");
  const [valueQuantityBarang, setValueQuantityBarang] = useState("");
  const [valueCashFlow,setValueCashFlow]=useState("outcome");
  const [disabled,setDisabled]=useState(false);
  const [keterangan,setKeterangan]=useState("");
  //
  async function AmbilDataBarang(val) {
    await fetch(url + "/?op=getDataInventoryGymPemasukan")
      .then((response) => response.json())
      .then((json) => {
        if(json!==null){
        setDataBarang(json);}
        else{
          setDataBarang([]);
        }
      });
  }
  //
  const KlikTr = (val) => {
    setDivInput("visibleDivInput");
    setValueNamaBarang(val.nama_barang);
   
  };
  //
  const KlikNew = () => {
    setDivInput("visibleDivInput");
    setValueNamaBarang("");
    setValueHargaBarang("");
    setValueQuantityBarang("");
  };
  //
  const Kirim = () => {
    if(valueNamaBarang===""||valueHargaBarang===""
    ||valueQuantityBarang===""){
      setKeterangan("isi data dengan lengkap");
      setDisabled(false);
    }else{
    setDisabled(true);
    fetch(url + "/?op=inputCashFlowInventoryGym", {
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body:
        "nama_barang=" +
        valueNamaBarang +
        "&harga_barang=" +
        valueHargaBarang +
        "&quantity_barang=" +
        valueQuantityBarang+
        "&keterangan=" +
        valueCashFlow
    })
    .then((response)=>response.json())
    .then((json)=>{
        if(json==="berhasil"){
            setDivInput("notVisibleDivInput");
            AmbilDataBarang();
            setValueNamaBarang("");
            setValueHargaBarang("");
            setValueQuantityBarang("");
            setValueCashFlow("outcome");
            setDisabled(false);
            setKeterangan("");
        }else{
          setKeterangan("tidak ada internet");
          setDisabled(false);
        }
    })}
  };
  useEffect(() => {
    AmbilDataBarang();
  }, []);
  return (
    <div className={style.containerAll}>
      <div className={style.judul}>
        <div className={style.tulisanJudul}>Inventory Gym</div>
        <div onClick={() => KlikNew()} className={style.tulisanNew}>
          new
        </div>
      </div>
      <div className={style[`${divInput}`]}>
        <h2>Input</h2>
        <input
          onChange={(e) => setValueNamaBarang(e.target.value)}
          value={valueNamaBarang}
          placeholder="Nama Barang"
        />
        <input
          onChange={(e) => setValueHargaBarang(e.target.value)}
          value={valueHargaBarang}
          placeholder="Harga Beli Barang Total"
          type="number"
        />
        <input
          onChange={(e) => setValueQuantityBarang(e.target.value)}
          value={valueQuantityBarang}
          placeholder="Quantity Total"
          type="number"
        />
        <select value={valueCashFlow} onChange={(e)=>setValueCashFlow(e.target.value)}>
            <option>
                outcome
            </option>
            <option>
                income
            </option>
          
        </select>
        <div className={style.keterangan}>{keterangan}</div>
        <button disabled={disabled} onClick={() => Kirim()}>Kirim</button>
      </div>
      <div className={style.divTable}>
        <table className={style.table}>
          <thead>
            <tr className={style.trSticky}>
              <th>Nama Barang</th>
            
            </tr>
          </thead>
          <tbody>
            {dataBarang.map((val, index) => (
              <tr
                style={{ cursor: "pointer" }}
                onClick={() => KlikTr(val)}
                key={index}
              >
                <td> {val.nama_barang}</td>
            
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default OwnerInOut;
