import React, { forwardRef, useImperativeHandle } from "react";
import { useState } from "react";
import style from "./style.module.css";

const SpinnerForm=forwardRef((props,ref)=>{
    const[nilai,setNilai]=useState("Pilih");

    //
    useImperativeHandle(ref, () => ({
        clearValue() {
            setNilai("Pilih");
        }
    }));
//function option select
const OptionSelect =(id)=>{
switch(id){
    case"waktuMemberGym":
    return(<>
<option>Pilih</option>
    <option>1 Bulan</option>
    <option>3 Bulan</option>
    <option>6 Bulan</option>
    <option>12 Bulan</option>
    </>
    )
    case"waktuGymPerpanjangan":
    return(<>
<option>Pilih</option>
    <option>1 Bulan</option>
    <option>3 Bulan</option>
    <option>6 Bulan</option>
    <option>12 Bulan</option>
    </>
    )
    
    case"statusMemberGym":
    return(<>
<option>Pilih</option>
    <option>Member Umum</option>
    <option>Member Pelajar</option>
    <option>Karyawan</option>
    <option>Trainer</option>
    </>
    )

    case"statusMemberZumba":
    return(<>
<option>Pilih</option>
    <option>karyawan</option>
    <option>trainer</option>
    </>
    )

    case"paketPertemuan":
    return(<>
<option>Pilih</option>
    <option>4 x Meeting</option>
    <option>10 x Meeting</option>
    <option>15 x Meeting</option>
    <option>20 x Meeting</option>
    <option>25 x Meeting</option>
    </>
    )
    case"statusPertemuan":
    return(<>
<option>Pilih</option>
    <option>One-on-One Session</option>
    <option>Buddy / Couple Training</option>

    </>
    )
    case"pilihanTrainer":
    return(<>
<option>Pilih</option>
    <option>Bebas</option>
    <option>Cewek</option>
    <option>Perpanjangan</option>
    </>
    )
    case"genderGym":
    return(<>
<option>Pilih</option>
    <option>cowok</option>
    <option>cewek</option>

    </>
    )

    case"pembayaranGym":
    return(<>
<option>Pilih</option>
    <option>Cash</option>
    <option>Non Cash</option>

    </>
    )
    case"pembayaranGymPerpanjangan":
    return(<>
<option>Pilih</option>
    <option>Cash</option>
    <option>Non Cash</option>

    </>
    )
    case"pembayaranFreelance":
    return(<>
<option>Pilih</option>
    <option>Cash</option>
    <option>Non Cash</option>
    </>
    )

    case"pembayaranKasir":
    return(<>
<option>Pilih</option>
    <option>Cash</option>
    <option>Non Cash</option>
    </>
    )

    case"pembayaranTrainer":
    return(<>
<option>Pilih</option>
    <option>Cash</option>
    <option>Non Cash</option>
    </>
    )
    
    default:
        break;
}
}

    const Change=(val)=>{
        setNilai(val);
localStorage.setItem(props.id,val);
if(props.id==="statusMemberGym"){
    if((val === "Member Umum")||(val === "Member Pelajar")){
        props.ValSpinner("muncul");
        props.ValSpinnerGender("sembunyi");
    }
    else if(val === "Trainer"){
        props.ValSpinnerGender("muncul");
        props.ValSpinner("sembunyi");
    }
    else{
 props.ValSpinner("sembunyi");
 props.ValSpinnerGender("sembunyi");
    }
}
else if(props.id==="pilihanTrainer"){
    if(val === "Cewek"){
    props.ValSpinner("muncul");
}else{
    props.ValSpinner("sembunyi");
}

}
else if(props.id === "waktuGymPerpanjangan"){
props.ValSpinner(val);
}
else if(props.id === "waktuMemberGym"){
    props.ValSpinner(val);
    }

    }
    return(
<div className={style.containerAll}>
<div> {props.nama}</div>
<input readOnly={true} />
<select value={nilai}  onChange={(e)=>Change(e.target.value)}>
{OptionSelect(props.id)}

</select>
</div>
    )
});

export default SpinnerForm;